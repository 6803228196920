export function Future() {
  return (
    <div className="bg-[#EBE8E2] h-screen flex justify-center">
      <p>
        Idk really but I wanna put more cool things up on here... <br />
        so come back another time
      </p>
    </div>
  );
}
